<template>
  <!-- 즐겨찾기한 코치 -->
  <coach-my-page-content-layout user-liked-coaches>
    <my-page-title>
      <template #left>
        <span>MY 즐겨찾기</span>
      </template>
    </my-page-title>
    <div class="search-summary">
      <span class="coach-count">
        <em class="em-bk">{{ addCommaDecimal(coachSearchResult.totalCount) }}</em>명의 코치님
      </span>
      <CheckboxSquare v-model="isOnline" class="live-filter">접속중인 코치님만 보기</CheckboxSquare>
    </div>
    <div class="coach-list">
      <CoachRowList :coach-search-result="coachSearchResult" />
      <div v-if="coachSearchResult.items < 1" class="empty-coach-list">
        <img src="/img/coaching/emoji-eyes.svg" alt="no-content-icon" />
        <p style="margin-top: 16px;">즐겨찾기한 코치님이 없습니다.</p>
        <p>나에게 맞는 코치님을 찾아보세요.</p>
        <button class="selected" @click="$router.push({ name: 'CoachFinder' })">나를 위한 코치님 찾기</button>
      </div>
      <area-more class="area-more" v-if="hasMore" @click="getMore" />
    </div>
  </coach-my-page-content-layout>
</template>

<script>
import { commaDecimal } from '@shared/utils/numberUtils';
import CoachMyPageContentLayout from '@/views/layout/CoachMyPageContentLayout.vue';
import MyPageTitle from '@/views/components/coaching/coach-my-page/MyPageTitle.vue';
import CoachRowList from '@/views/components/coaching/coachList/CoachRowList.vue';
import AreaMore from '@/views/components/coaching/question/AreaMore.vue';
import CheckboxSquare from '@/views/components/common/input/CheckboxSquare.vue';
import { removeEventHandler, setEventHandler } from '@/utils/eventBusUtils';

export default {
  name: 'CoachUserLikedCoaches',
  components: { CoachMyPageContentLayout, MyPageTitle, CoachRowList, AreaMore, CheckboxSquare },
  data() {
    return {
      coachSearchResult: {},
      pagingSize: 5,
    };
  },
  metaInfo() {
    return {
      title: this.$t('meta.myLikedCoach.title'),
      meta: [
        { vmid: 'title', content: this.$t('meta.myLikedCoach.title') },
        { vmid: 'url', content: this.$t('meta.myLikedCoach.url') },
        { vmid: 'description', content: this.$t('meta.description') },
        { vmid: 'keywords', content: this.$t('meta.keywords') },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: this.$t('meta.type') },
        { vmid: 'image', content: this.$t('meta.image') },
      ],
    };
  },
  mounted() {
    setEventHandler('coach-list-get-list', this.getCoachList.bind(this));
  },
  computed: {
    hasMore() {
      return this.coachSearchResult.hasNext;
    },
    isOnline: {
      get() {
        return this.$route?.query?.isOnline === 'true';
      },
      set(v) {
        this.$router.push({ query: this.getMergedQuery({ isOnline: v, cursor: 0 }) });
      },
    },
    order: {
      get() {
        return this.$route?.query?.order ?? 'bookmarkAdded DESC';
      },
      set(v) {
        this.$router.push({ query: this.getMergedQuery({ order: v, cursor: 0 }) });
      },
    },
  },
  methods: {
    addCommaDecimal(num) {
      return num ? commaDecimal(num) : 0;
    },
    getCoachList() {
      this.coachSearchResult.nextCursor = 0;
      this.coachSearchResult.items = [];
      this.getMore();
    },
    async getMore() {
      const { nextCursor } = this.coachSearchResult;
      this.coachSearchResult.nextCursor = nextCursor + 1;
      const moreCoachResult = await this.$services.coaching.getCoachList({ cursor: nextCursor, size: this.pagingSize, isBookmark: true, order: this.order, silent: true });
      this.coachSearchResult.nextCursor = moreCoachResult.nextCursor;
      this.coachSearchResult.hasNext = moreCoachResult.hasNext;
      this.coachSearchResult.totalCount = moreCoachResult.totalCount;
      moreCoachResult.items.forEach(coach => this.coachSearchResult.items.push(coach));
    },
  },
  beforeDestroy() {
    removeEventHandler('coach-list-get-list');
  },
  async asyncData({ services, route }) {
    const cursor = route.query.cursor ?? 0;
    const search = route.query.search ?? '';
    const isOnline = route.query.isOnline === 'true';
    const order = route.query.order ?? 'bookmarkAdded DESC';
    const isBookmark = 'true';
    const coachSearchResult = await services.coaching.getCoachList({ cursor, size: 5, order, search, isOnline, isBookmark, silent: true });
    return { coachSearchResult };
  },
};
</script>
<style lang="less">
@import '~@/less/proj.less';

[user-liked-coaches] {
  .search-summary { .flex; .pt(30); .pb(30); border-bottom: solid 1px #ebebeb;
    .coach-count { .w(50%); .tl; .c(#777);
      .em-bk { .c(#191919); }
      .em-bl { .c(#2c81ff) }
    }
    .live-filter { .ml(auto); }
  }
  .empty-coach-list {.h(309); .pt(80); .tc;
    > button { .w(224); .p(17, 20); .mt(16); .bgc(#191919); .c(#fff); border: 1px solid #ebebeb; .fs(14); .br(4);
    }
  }
  .area-more { .mt(28); }

  @media (@tp-down) {
    .p(20, 20, 20, 20);
    .search-summary { .flex-wrap(); .pt(20); .pb(20); border-bottom: solid 1px #ebebeb;
      .coach-count { .w(100%); .p(12, 12, 12, 12); .bgc(#f6f6f6); .tl; .c(#777);
        .em-bk { .c(#191919); }
        .em-bl { .c(#2c81ff) }
      }
      .live-filter { .ml(auto); .pt(20); }
    }
  }
}

</style>
